import React, { useContext, useEffect, useState } from 'react';
import { Card, Button, Checkbox, List, message, Typography, Modal,Row,Col,InputNumber,Progress } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import * as bitcoin from 'bitcoinjs-lib';
import { Psbt } from 'bitcoinjs-lib/src/psbt';
import { PsbtInput } from 'bip174/src/lib/interfaces';
import varuint from 'varuint-bitcoin';

import { ConnectionContext } from './ConnectionContext';

const { Text } = Typography;

interface Inscription {
  inscriptionId: string;
  content: string;
  jsonData: {
    p: string;
    op: string;
    tick: string;
    amt: number;
  };
  location: string;
  vout: number; // Ajout de vout au type Inscription
  txid: string; // Ajout de txid au type Inscription
}
interface Rune {
  rune: string;
  runeId: string;
  spacedRune: string;
  amount: string;
  symbol: string;
  divisibility: number;
  txid: string;
  vout: number;
}
interface JsonData {
  p: string;
  op: string;
  tick: string;
  amt: string;
}
interface Campaign {
  name: string;
  total: string;
  tvl: number;
}

const totalSupply: { [key: string]: number } = {
  OSHI: 13337, 
  FCDP: 16180,
  CLTV: 215792660,
  SATS: 2100000000000000,
  ORDI: 21000000 ,
  PIZZA: 21000000 ,
  TRAC: 21000000,
  "DOG•GO•TO•THE•MOON": 100000000000,
  "DOTSWAP•DOTSWAP":926880620  
  // Ajoutez d'autres jetons ici
};

const Borrow: React.FC = () => {
  const {isConnected, currentAccount,getPublicKey,signPsbt,stakingAddress } = useContext(ConnectionContext);

  // Claim state
  const [claiming, setClaiming] = useState(false);
  const [claimTokenType, setClaimTokenType] = useState<string | null>(null);
  const [isClaimModalVisible, setIsClaimModalVisible] = useState(false);

  // Unstake state
  const [staking, setStaking] = useState<boolean>(false);
  const [isUnstakeModalVisible, setIsUnstakeModalVisible] = useState(false);
  const [unstakeTokenType, setUnstakeTokenType] = useState<string | null>(null);
  const [unstakeAmount, setUnstakeAmount] = useState<number | null>(null);
  const [fees, setFees] = useState<number>(10);

  // UTXO state
  const [utxo, setUTXO] = useState<any[]>([]);
  const [isUTXOModalVisible, setIsUTXOModalVisible] = useState(false);
  const [selectedUTXO, setSelectedUTXO] = useState<any[]>([]);

  // Inscriptions state
  const [selectedInscriptions, setSelectedInscriptions] = useState<Inscription[]>([]);
  const [inscriptions, setInscriptions] = useState<Inscription[]>([]);
  const [selectedToken, setSelectedToken] = useState<string | null>(null);

  // Runes state
  const [selectedRunes, setSelectedRunes] = useState<Rune[]>([]);
  const [isRuneModalVisible, setIsRuneModalVisible] = useState(false);
  const [runes, setRunes] = useState<Rune[]>([]);

  // Other
  const [totalValueLocked, setTotalValueLocked] = useState<number>(7250218);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  useEffect(() => {
    if (isConnected && currentAccount) {
      // ['OSHI', 'FCDP'].forEach(fetchInscriptions);
    }
    fetchTotalValueLocked();

  }, [isConnected, currentAccount]);

  const fetchTotalValueLocked = async (): Promise<void> => {
    try {
      const response = await fetch('/api/stakings/tvl');
      if (response.ok) {
        const data = await response.json();
        setTotalValueLocked(data.totalValueLocked);
        setCampaigns(data.campaigns || []); 
      } else {
        throw new Error('Failed to fetch total value locked');
      }
    } catch (error) {
      console.error('Error fetching total value locked:', error);
    }
  };
  const renderProgress = (tokenName: string) => {
    const campaign = campaigns.find((c: Campaign) => c.name === tokenName);
    console.log("Campaign:", campaign);

    if (campaign) {
      const total = parseFloat(campaign.total);
      const supply = totalSupply[tokenName] || 1;
      console.log("Total:", total);
      console.log("Supply:", supply);

      const percent = ((total / supply) * 100).toFixed(2);
      console.log("Percent:", percent);

      return (
        <div style={{ width: '100%' }}>
          <Text strong>{`${total} / ${supply} ${tokenName} locked`}</Text>
          <Progress 
            percent={parseFloat(percent)} 
            status="active" 
            strokeColor="#0d3a4d" 
            style={{ width: '100%' }} 
          />
        </div>
      );
    }

    return null;
  };

  const fetchUTXO = async () => {
    if (!currentAccount) return;
    try {
      const response = await fetch(`/api/fetchUTXO?currentAccount=${currentAccount}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const data = await response.json();


      // Remplacement des UTXO disponibles par les nouvelles données
      setUTXO(data);
    } catch (error) {
      console.error('Error fetching UTXO:', error);
    }
  };


// https://open-api.unisat.io/v1/indexer/address/{address}/runes/{runeid}/utxo
  const fetchRune = async (runeId: string) => {
      if (!currentAccount) return;
      try {
          const response = await fetch(`/api/fetchRunes?currentAccount=${currentAccount}&runeId=${runeId}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
              }
          });

          const { code, data } = await response.json();

          if (code === 0 && data && data.utxo && data.utxo.length > 0) {
              const newRunes: Rune[] = data.utxo.flatMap((utxo: any) => {
                  if (utxo.runes && utxo.runes.length > 0) {
                      return utxo.runes.map((runeData: any) => ({
                          rune: runeData.rune,
                          runeId: runeData.runeid,
                          spacedRune: runeData.spacedRune,
                          amount: runeData.amount,
                          symbol: runeData.symbol,
                          divisibility: runeData.divisibility,
                          txid: utxo.txid, // Ajout de la txid
                          vout: utxo.vout // Ajout du vout
                      }));
                  }
                  return [];
              });

              // Remplacement des runes disponibles par les nouvelles runes
              setRunes(newRunes);
          }
      } catch (error) {
          console.error('Error fetching runes:', error);
      }
  };



  const fetchInscriptions = async (ticker: string) => {
    if (!currentAccount) return;
    try {
      const response = await fetch(`/api/fetchInscriptions?currentAccount=${currentAccount}&ticker=${ticker}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      const { code, data } = await response.json();

      if (code === 0 && data.detail) {
        // Pour chaque inscription, récupérer le vout et l'ajouter à l'objet d'inscription
        const newInscriptions = await Promise.all(data.detail.map(async (item: any) => {
          try {
            // Récupérer le vout pour cette inscription
            const inscriptiondetail = await fetch(`/api/inscriptionInfo/${item.inscriptionId}`, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            });

            const detail = await inscriptiondetail.json();
            const vout= detail.data.utxo.vout;
            const txid= detail.data.utxo.txid;

            // Créer un nouvel objet d'inscription avec le vout ajouté
            return {
              inscriptionId: item.inscriptionId,
              content: '',
              jsonData: {
                p: 'brc-20',
                op: item.data.op,
                tick: ticker,
                amt: item.data.amt
              },
              location: '',
              vout: vout,
              txid:txid
            };
          } catch (error) {
            console.error(`Error fetching vout for inscription ${item.inscriptionId}:`, error);
            return null;
          }
        }));

        // Filtrer les inscriptions avec des vout valides
        const validInscriptions = newInscriptions.filter((inscription: any) => inscription !== null);

        // Ajouter les nouvelles inscriptions à l'état
        setInscriptions(prev => [...prev, ...validInscriptions]);
      }
    } catch (error) {
      console.error('Error fetching inscriptions:', error);
    }
  };

  const toggleInscriptionSelection = (inscription: Inscription) => {
    setSelectedInscriptions(prev => prev.some(sel => sel.inscriptionId === inscription.inscriptionId) ? 
      prev.filter(sel => sel.inscriptionId !== inscription.inscriptionId) : [...prev, inscription]);
  };
  const toggleRuneSelection = (rune: Rune) => {
      setSelectedRunes(prev => {
          const isSelected = prev.some(sr => sr.runeId === rune.runeId);
          if (isSelected) {
              return prev.filter(sr => sr.runeId !== rune.runeId);
          } else {
              return [...prev, rune];
          }
      });
  };

  const toggleUTXOSelection = (utxo: any) => {
    setSelectedUTXO(prev => prev.some(sel => sel.txid === utxo.txid && sel.vout === utxo.vout) ? 
      prev.filter(sel => !(sel.txid === utxo.txid && sel.vout === utxo.vout)) : [...prev, utxo]);
  };

const handleStakeUTXO = async () => {
  try {
    setStaking(true);
    const pubKey = await getPublicKey();

    for (const utxo of selectedUTXO) {
      const { txid, vout, satoshi } = utxo;

      const stakeResponse = await fetch(`/api/stakeBTC`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taproot: currentAccount,
          pubKey,
          txid,
          vout,
          amt: satoshi,
        }),
      });

      if (stakeResponse.ok) {
        const stakeResponseData = await stakeResponse.json();
        const psbtHex = stakeResponseData.hex;

        const signedPsbtHex = await (window as any).unisat.signPsbt(psbtHex, {
          autoFinalized: false,
          disableTweakSigner: false,
        });

        const response = await fetch(`/api/finalizeStakeBTC`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            taproot: currentAccount,
            pubKey,
            txid,
            vout,
            psbtHex: signedPsbtHex,
            amt: satoshi,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to finalize stake');
        } else {
          const responseData = await response.json();
          const txHex = responseData.txHex;

          try {
            await (window as any).unisat.pushTx(txHex);
            message.success('Stake successful!');
            handleHideModal();
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        message.error('Staking failed.');
      }
    }
  } catch (error) {
    console.error('Error staking tokens:', error);
    message.error('An error occurred while staking tokens.');
  } finally {
    setStaking(false);
    handleHideModal();
  }
};



const handleStake = async () => {
  try {
    setStaking(true);

    // Récupérer la clé publique
    const pubKey = await getPublicKey();

    // Parcourir les inscriptions sélectionnées
    for (const inscription of selectedInscriptions) {
      const { txid, vout, jsonData } = inscription;
      const { tick, amt } = jsonData;
      const tickmaj = tick.toUpperCase();

      // Déterminez le campaignid en fonction du ticker
      const campaignIdMap: { [key: string]: number } = {
        'oshi': 6,
        'fcdp': 5,
        'cltv': 3,
        'btc': 4,
        'sats': 7,
        'ordi': 12,
        'pizza': 11,
        'dog': 8,
        'dswp': 10,
        'trac': 9
      };
      const campaignid = campaignIdMap[tick.toLowerCase()];

      // Effectuer la requête POST vers l'API de stake avec les données récupérées
      const stakeResponse = await fetch(`/api/stake`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taproot: currentAccount, pubKey, txid, vout, campaignid, amt }),
      });

      // Vérifier la réponse de l'API de stake
      if (stakeResponse.ok) {
        const stakeResponseData = await stakeResponse.json(); 
        const psbtHex = stakeResponseData.hex;
        const signedPsbtHex = await (window as any).unisat.signPsbt(psbtHex, {
          autoFinalized: false,
          disableTweakSigner: false
        });
        const response = await fetch(`/api/finalizeStake`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ taproot: currentAccount, pubKey, txid, vout, psbtHex: signedPsbtHex, campaignid, amt }),
        });

        if (!response.ok) {
          throw new Error('Failed to finalize stake');
        } else {
          const responseData = await response.json(); 
          const txHex = responseData.txHex;
          try {
            let res = await (window as any).unisat.pushTx(txHex);
            message.success('Stake successful!');
            handleHideModal();
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        message.error('Staking failed.');
      }
    }
  } catch (error) {
    console.error('Error staking tokens:', error);
    message.error('An error occurred while staking tokens.');
  } finally {
    setStaking(false);
    handleHideModal();
  }
};


const handleStakeRune = async () => {
  try {
    setStaking(true);

    // Récupérer la clé publique
    const pubKey = await getPublicKey();

    // Parcourir les runes sélectionnées
    for (const rune of selectedRunes) {
      const { txid, vout, amount, runeId } = rune;
      let campaignId;
      if (runeId === "840000:3") {
        campaignId = 8;
      } else if (runeId === "840456:5478") {
        campaignId = 10;
      }
      

      // Effectuer la requête POST vers l'API de stakeRune avec les données récupérées
      const stakeResponse = await fetch(`/api/stakeRune`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taproot: currentAccount,
          pubKey,
          txid,
          vout,
          campaignid: campaignId,
          runeid: runeId,
          amt: parseInt(amount),
        }),
      });

      // Vérifier la réponse de l'API de stakeRune
      if (stakeResponse.ok) {
        const stakeResponseData = await stakeResponse.json();
        const psbtHex = stakeResponseData.hex;
        const signedPsbtHex = await (window as any).unisat.signPsbt(psbtHex, {
          autoFinalized: false,
          disableTweakSigner: false,
        });

        const response = await fetch(`/api/finalizeStakeRune`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            taproot: currentAccount,
            pubKey,
            txid,
            vout,
            psbtHex: signedPsbtHex,
            campaignid: campaignId,
            runeid: runeId,
            amt: parseInt(amount),
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to finalize stake');
        } else {
          const responseData = await response.json();
          const txHex = responseData.txHex;

          try {
            let res = await (window as any).unisat.pushTx(txHex);
            message.success('Rune stake successful!');
            handleHideModal();
          } catch (e) {
            console.error(e);
          }
        }
      } else {
        // Afficher un message d'erreur si le staking échoue
        message.error('Rune staking failed.');
      }
    }
  } catch (error) {
    // Gérer les erreurs
    console.error('Error staking runes:', error);
    message.error('An error occurred while staking runes.');
  } finally {
    // Réinitialiser l'état de staking et fermer la modal
    setStaking(false);
    handleHideModal();
  }
};

const handleRestake = async () => {
  try {
    setStaking(true);

    const pubKey = await getPublicKey();
    if (!pubKey) throw new Error('Public key not available');

    const response = await fetch(`/api/restake`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        taproot: currentAccount,
        pubKey,
        fromBlockheight: 847244,
        toBlockheight: 858052,
      }),
    });

    if (response.ok) {
      const restakeResponseData = await response.json();
      const psbtHex = restakeResponseData.hex;
      console.log(psbtHex);
      const toSignInputs: { index: number; publicKey: string }[] = [];
      // Ajoutez les types de Sighash uniquement pour le premier input
      toSignInputs.push({ index: 0, publicKey: pubKey });

      // Utilisez un Sighash approprié pour les autres inputs si nécessaire
      for (let i = 1; i < restakeResponseData.txInputs.length; i++) {
          toSignInputs.push({ index: i, publicKey: pubKey }); // Aucun type de Sighash pour les autres inputs
      }
     const signedPsbtHex = await (window as any).unisat.signPsbt(psbtHex, {
       autoFinalized: false,
       toSignInputs,
       disableTweakSigner: false,
     });
     console.log("test",signedPsbtHex);

      const responseFinalize = await fetch(`/api/finalizeRestake`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          taproot: currentAccount,
          pubKey,
          fromBlockheight: 847244,
          toBlockheight: 858052,
          psbtHex: signedPsbtHex,
        }),
      });

      if (responseFinalize.ok) {
        const responseData = await responseFinalize.json();
        const txHex = responseData.txHex;
        console.log(txHex);
        try {
          await (window as any).unisat.pushTx(txHex);
          message.success('Restake successful!');
        } catch (e) {
          console.error('Error pushing transaction:', e);
          message.error('Failed to push transaction.');
        }
      } else {
        console.error('Failed to finalize restake:', responseFinalize.statusText);
        message.error('Failed to finalize restake.');
      }
    } else {
      console.error('Failed to initiate restake:', response.statusText);
      message.error('Failed to initiate restake.');
    }
  } catch (error) {
    console.error('Error during restake:', error);
    message.error('An error occurred while restaking.');
  } finally {
    setStaking(false);
  }
};


 

const handleUnstake = async () => {
    if (!unstakeAmount || !unstakeTokenType || !currentAccount) return;
    const unstakeResponse = await fetch(`/api/unstake`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ receiveAddress: stakingAddress, brc20Ticker:unstakeTokenType,brc20Amount:unstakeAmount,feeRate:fees }),
    });
    if (!unstakeResponse.ok) {
           throw new Error('Failed to Unstake inscription order ');
       }else{
          const responseData = await unstakeResponse.json(); 
          const {payAddress,amount,feeRate}=responseData.data;

             try {
               let res = await (window as any).unisat.sendBitcoin(payAddress, amount, { feeRate });
               console.log(res)
               message.success(`${unstakeAmount} ${unstakeTokenType} unstaked successfully!`);
               handleHideUnstakeModal();

             } catch (e) {
               console.log(e);
             }

       }

};

  const claim = async (psbtHex: string, pubKey: string) => {
      const psbt1 = Psbt.fromHex(psbtHex);
      const toSignInputs: { index: number; publicKey: string }[] = [];
      // Ajoutez les types de Sighash uniquement pour le premier input
      toSignInputs.push({ index: 0, publicKey: pubKey });

      // Utilisez un Sighash approprié pour les autres inputs si nécessaire
      for (let i = 1; i < psbt1.txInputs.length; i++) {
          toSignInputs.push({ index: i, publicKey: pubKey }); // Aucun type de Sighash pour les autres inputs
      }

      const signedPsbtHex = await (window as any).unisat.signPsbt(psbt1.toHex(), {
        toSignInputs,
        autoFinalized: false,
        disableTweakSigner: false
      });
      // Recharger la PSBT signée
      const psbt = Psbt.fromHex(signedPsbtHex);
      const response = await fetch(`/api/finalizeClaim`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ psbtHex: signedPsbtHex }),
      });

      if (!response.ok) {
             throw new Error('Failed to finalize claim');
         }else{
            const responseData = await response.json(); 
            return {
                txHex: responseData["txHex"],
            };
         }
  };


  const handleClaim = async () => {
    setClaiming(true);
    const pubKey = await getPublicKey();

    // Déterminer le campaignid à partir du ticker
    const ticker = claimTokenType?.toLowerCase();
    const campaignIdMap: { [key: string]: number } = {
      'oshi': 6,
      'fcdp': 5,
      'cltv': 3,
      'btc': 4,
      'sats': 7,
      'ordi': 12,
      'pizza': 11,
      'dog': 8,
      'dswp': 10,
      'trac': 9,
    };
    const campaignid = campaignIdMap[ticker || ''];

    if (!campaignid) {
      message.error('Invalid token type for claiming.');
      setClaiming(false);
      return;
    }

    try {
      const response = await fetch(`/api/claim`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ taproot: currentAccount, pubKey: pubKey, campaignid }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const psbtHex = responseData.hex;

        // Réclamer les tokens en utilisant la nouvelle fonction claim
        const { txHex: txHex } = await claim(psbtHex, pubKey!);
        try {
          let res = await (window as any).unisat.pushTx(txHex);
          console.log(res);
          message.success('Claim successful!');
          handleHideModal();
        } catch (e) {
          console.log(e);
        }
      } else {
        // Réclamation échouée
        message.error('Claim failed.');
      }
    } catch (error) {
      // Gérer les erreurs
      console.error('Error claiming tokens:', error);
      message.error('An error occurred while claiming tokens.');
    } finally {
      // Réinitialiser l'état de claiming et fermer la modal
      setClaiming(false);
      handleHideModal();
    }
  };



//gestion des modals 
const showModal = async (tokenType: string) => {
  // Réinitialiser la liste des inscriptions et des runes
  setInscriptions([]);
  setRunes([]);
  setUTXO([]); // Réinitialiser la liste des UTXO

  // Appeler les fonctions appropriées pour récupérer les inscriptions, runes ou UTXO
  try {
    if (isConnected && currentAccount) {
      if (tokenType === "dog" || tokenType === "dswp") {
        let runeId = "";
        if (tokenType === "dog") {
          runeId = "840000:3";
        } else if (tokenType === "dswp") {
          runeId = "840456:5478";
        }
        await fetchRune(runeId);
        setIsRuneModalVisible(true); // Afficher la modal des runes pour les jetons DOG et DSWP
        setSelectedToken(tokenType); // Mettre à jour le jeton sélectionné
      } else if (tokenType === "btc") {
        await fetchUTXO(); // Récupérer les UTXO pour le jeton BTC
        setSelectedToken(tokenType); // Mettre à jour le jeton sélectionné
        setIsUTXOModalVisible(true); // Afficher la modal des UTXO pour le jeton BTC
      } else {
        await fetchInscriptions(tokenType);
        setSelectedToken(tokenType); // Mettre à jour le jeton sélectionné pour les autres types de jetons
        setIsModalVisible(true); // Afficher la modal des inscriptions pour les autres types de jetons
      }
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

const handleShowModal = (tokenType: string) => {
  setIsModalVisible(true);
  setClaimTokenType(tokenType);
};

const handleHideModal = () => {
  setIsModalVisible(false);
  setClaimTokenType(null);
};

const handleShowClaimModal = (tokenType: string) => {
  console.log("test");
  console.log(tokenType);
  setIsClaimModalVisible(true);
  setClaimTokenType(tokenType);
};
const handleHideClaimModal = () => {
  setIsClaimModalVisible(false);
  setClaimTokenType(null);
};

const handleShowUnstakeModal = (tokenType: string) => {
  setIsUnstakeModalVisible(true);
  setUnstakeTokenType(tokenType);
};
const handleFeesChange = (value: number | null) => {
    setFees(value !== null ? value : 10);
};

const handleHideUnstakeModal = () => {
  setIsUnstakeModalVisible(false);
  setUnstakeTokenType(null);
  setUnstakeAmount(null);
};

  const listItemStyle: React.CSSProperties = {
    border: '1px solid #F2F2F2',
    borderRadius: '8px',
    marginBottom: '20px',
    padding: '25px',
    cursor: 'pointer',
    width: '750px',
    position: 'relative',
  };

  const arrowIconStyle: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
  };

  const faqData = [
    {
      question: 'How to stake BRC-20 tokens ?',
      answer: 'Go to the CLTV Finance Staking section. Click on stake. Select the transfer inscribe and sign, only 1 action to stake your BRC-20s: Select the correct transfer inscribe you want to stake and sign the PSBT in your wallet to engage your BRC-20 tokens: This signature actually performs the action to stake BRC-20 tokens, locking your BRC-20 tokens on-chain with a script in your smart wallet.'
    },
    {
      question: 'How to unstake BRC-20 tokens ?',
      answer: 'Go to the CLTV finance Staking section. Insert the transfer inscribe quantity, input the desired fees and click on Unstake. Only 2 actions to unstake your BRC-20 tokens: Sign the PSBT in your wallet and wait for 3 blocks: This signature actually performs the action to unstake your BRC-20s. \n Once the 3 blocks have passed, click on claim and sign the PSBT, unlocking your BRC-20s from the smart wallet. Your BRC-20 tokens are backed in your wallet, you can dispose of it at your convenience.'
    },
    {
      question: 'How to claim CLTV rewards ?',
      answer: 'No need to claim your rewards, CLTV Finance takes care of it. CLTV tokens will be airdropped for free, automatically into your wallet at the end of the staking campaign. Enjoy juicy rewards for free! You can dispose of your CLTV tokens at your convenience.'
    },
    {
      question: 'What is the cost of interacting with CLTV finance ?',
      answer: 'Interacting with CLTV finance for staking and unstaking requires transactions and so transaction fees for Bitcoin Blockchain usage, these fees depend on the network status and transaction complexity. Always check for network activity and interact wisely when gas fees are low.'
    },
    {
      question: 'Where are my staked funds stored ?',
      answer: 'Your funds stay in your wallet! This is the main difference with ERC20 standard staking protocols where your tokens are allocated in a pool of a smart contract. With CLTV finance, your tokens are encapsulated into a script on-chain owned by your wallet. Your wallet has become a smart wallet.'
    },
    {
      question: 'What is a Smart Wallet ?',
      answer: 'A smart wallet is an extension of a non-custodial Bitcoin wallet that integrates programmable functionalities to automate and enhance various financial operations.'
    },
    {
      question: 'How does a Smart Wallet differ from a Traditional Wallet ?',
      answer: 'Traditional wallets utilize smart contract technology. Smart wallets leverage native Bitcoin functionalities such as HTLCs to execute predefined actions under specific conditions. This integration grants users full control over their assets without relying on third-party custody, enabling functionalities such as automated payments, conditional transfers, and even staking.'
    },
    {
      question: 'What is Liquidity Locking ?',
      answer: 'Liquidity locking is a process where cryptocurrency holders lock their tokens for a specified period using HTLC (Hashed Time-Locked Contracts) on the Bitcoin blockchain to create scarcity and demonstrate their commitment to a project as they renounce to the possibility of selling their tokens during the lock-up period. This locking helps build trust among project participants by showing a strong financial commitment. Additionally, liquidity locking can provide benefits such as rewards, extra returns, voting rights, or token airdrops.'
    }
  ];



  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const handleQuestionClick = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  return (
    <div style={{ justifyContent: 'center', textAlign: 'center',marginTop:'12%' }}>
    <Row justify="center" align="middle">
      <Col xs={12} sm={12} md={8}>
      <img src="m3rl1n_MasterChief.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />
      </Col>
      <Col xs={12} sm={12} md={8}>
        <div style={{ color: '#0d3a4d', textAlign: 'center' }}>
            <Text style={{ fontSize: 'calc(1.5vw + 1rem)', fontWeight: '600' }}>
            $ {totalValueLocked} <br />
            Total Value Locked in CLTV Finance liquidity staking
          </Text>
          <br />
          <br />

          <Text style={{ color: '#f7931a', fontSize: 'calc(1.5vw + 1rem)', fontWeight: '700', marginTop: '15px' }}>Unlock liquidity while earning rewards! </Text>
          <br />
          <br />

          <Text style={{ fontSize: 'calc(0.8vw + 0.8rem)', fontWeight: '500' }}>
            CLTV Finance is the 1st staking protocol on native Bitcoin chain for BRC-20 tokens – safe, simple, and non-custodial.
          </Text>

          {/*<Button style={{ backgroundColor: '#0d3a4d',color :'#f7931a',  fontSize: '19px' ,fontWeight: '400',height:'100%',margin:'5%', borderRadius: '20px',padding: '15px 50px',width: 'auto' }} >Start Staking Now</Button>*/}
        </div>
      </Col>
    </Row>
        <h1 style={{ fontSize: 'calc(2vw + 1rem)', fontWeight: '400', lineHeight: '120%' }}>Locking Menu</h1>

    <div className="box-container">
    <Row gutter={[16, 16]} justify="center" align="middle" style={{ backgroundColor: '#0d3a4d', color: '#f4f4f4', height:'auto'}}>
    <Col xs={24} sm={24} md={8} style={{  minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <div className="box">
      <img src="stake_cltv.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />

        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE CLTV and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All CLTV locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>15%</u> rewards of CLTV total supply </h3>
          {renderProgress('CLTV')}
          <p>Engage your CLTV on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('cltv')}>LOCK CLTV</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowUnstakeModal('cltv')}>UNLOCK CLTV</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('cltv')} disabled={claiming} >CLAIM CLTV </Button>
        </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} style={{  minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <div className="box">
        <img src="stake_btc.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />

        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE BTC (native) and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All BTC locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>6%</u> rewards of CLTV total supply </h3>
          <p>Engage your BTC on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('btc')}>LOCK BTC</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('btc')}  disabled={claiming} >CLAIM BTC</Button>
          </div>
        </div>
      </Col>

    </Row>
    </div>
    <div className="box-container">
    <Row gutter={[16, 16]} justify="center" align="middle" style={{ backgroundColor: '#0d3a4d', color: '#f4f4f4', height:'auto'}}>
    <Col xs={24} sm={24} md={8} style={{ minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
       <div className="box">
      <img src="stake_fcdp.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />
        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE FCDP and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All FCDP locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('FCDP')}
          <p>Engage your FCDP on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleRestake()}>RELOCK FCDP</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('fcdp')}>LOCK FCDP</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowUnstakeModal('fcdp')}>UNLOCK FCDP</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('fcdp')} disabled={claiming} >CLAIM FCDP </Button>
        </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} style={{ minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <div className="box">
        <img src="stake_oshi.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />
        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE OSHI and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All OSHI locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('OSHI')}
          <p>Engage your OSHI on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleRestake()}>RELOCK OSHI</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('oshi')}>LOCK OSHI</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowUnstakeModal('oshi')}>UNLOCK OSHI</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('oshi')}  disabled={claiming} >CLAIM OSHI</Button>
          </div>
        </div>
      </Col>

    </Row>
    </div>
    <div className="box-container">
    <Row gutter={[16, 16]} justify="center" align="middle" style={{ backgroundColor: '#0d3a4d', color: '#f4f4f4', height:'auto'}}>
    <Col xs={24} sm={24} md={8} style={{ minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
     <div className="box">
      <img src="stake_sats.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />

        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE SATS and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All SATS locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('SATS')}
          <p>Engage your SATS on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('sats')}>LOCK SATS</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowUnstakeModal('sats')}>UNLOCK SATS</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('sats')} disabled={claiming} >CLAIM SATS </Button>
        </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} style={{ minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <div className="box">
        <img src="stake_dog.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />

        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE DOG and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All DOG locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('DOG•GO•TO•THE•MOON')}
          <p>Engage your DOG on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('dog')}>LOCK DOG</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('dog')} disabled={claiming} >CLAIM DOG</Button>
          </div>
        </div>
      </Col>

    </Row>
    </div>
    <div className="box-container">
    <Row gutter={[16, 16]} justify="center" align="middle" style={{ backgroundColor: '#0d3a4d', color: '#f4f4f4', height:'auto'}}>
    <Col xs={24} sm={24} md={8} style={{  minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <div className="box">
      <img src="stake_trac.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />
        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE TRAC and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All TRAC locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('TRAC')}
          <p>Engage your TRAC on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('trac')}>LOCK TRAC</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowUnstakeModal('trac')}>UNLOCK TRAC</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('trac')} disabled={claiming} >CLAIM TRAC </Button>
        </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} style={{ minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <div className="box">
        <img src="stake_dswp.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />
        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE DSWP and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3 style={{ fontSize: '1.2em', marginBottom: '10px' }}>All DSWP locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('DOTSWAP•DOTSWAP')}
          <p>Engage your DSWP on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('dswp')}>LOCK DSWP</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('dswp')}  disabled={claiming} >CLAIM DSWP</Button>
          </div>
        </div>
      </Col>

    </Row>
    </div>
    <div className="box-container">
    <Row gutter={[16, 16]} justify="center" align="middle" style={{ backgroundColor: '#0d3a4d', color: '#f4f4f4', height:'auto'}}>
    <Col xs={24} sm={24} md={8} style={{ minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
     <div className="box">
      <img src="stake_pizza.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />

        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE PIZZA and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3>All PIZZA locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('PIZZA')}
          <p>Engage your PIZZA on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('pizza')}>LOCK PIZZA</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowUnstakeModal('pizza')}>UNLOCK PIZZA</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('pizza')}  disabled={claiming} >CLAIM PIZZA </Button>
        </div>
        </div>
      </Col>
      <Col xs={24} sm={24} md={8} style={{ minHeight: '350px', padding: '20px', color: '#f4f4f4', borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
       <div className="box">
        <img src="stake_ordi.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />

        <div>
          <h2 style={{ fontSize: '1.8em', marginBottom: '10px' }}>STAKE ORDI and <span style={{ color: '#0d3a4d' }}>EARN CLTV</span></h2>
          <h3>All ORDI locked in CLTV Finance share <u style={{ color: '#0d3a4d', fontSize: '1.5em' }}>3%</u> rewards of CLTV total supply </h3>
          {renderProgress('ORDI')}
          <p>Engage your ORDI on the liquidity staking campaign running until <u style={{ color: '#0d3a4d', fontSize: '1.5em' }} >23 August 2024</u></p>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => showModal('ordi')}>LOCK ORDI</Button>
          <Button style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowUnstakeModal('ordi')}>UNLOCK ORDI</Button>
          <Button   style={{ backgroundColor: '#0d3a4d',color: '#f4f4f4',marginTop: '20px',borderRadius: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }} onClick={() => handleShowClaimModal('ordi')} disabled={claiming} >CLAIM ORDI</Button>
          </div>
        </div>
      </Col>

    </Row>
    </div>
    <Row gutter={12} justify="center" align="middle" style={{ backgroundColor: '#f4f4f4', color: '#0d3a4d', minHeight: '400px', padding: '20px',textAlign: 'center' }}>
           <div>
              <Text style={{ fontSize: 'calc(1.5vw + 1rem)', fontWeight: '600' }}>Understand Liquidity Locking</Text> <br />
              <Text style={{ color: '#0d3a4d', fontSize: 'calc(0.5vw + 1rem)', fontWeight: '400', marginTop: '15px' }}>
                Experience a secure and rewarding staking with CLTV, earn passive income with the 1st non-custodial<br />  locking protocol on Bitcoin L1. Your funds stay in your wallet!<br />  With CLTV finance, your tokens are encapsulated into a script on-chain owned by your smart wallet
              </Text> 
            </div>
           <Row gutter={[16, 16]}>
             <Col xs={24}>
               <Row justify="center" gutter={[16, 16]}>
                 <Col xs={24} sm={12} md={6}>
                   <img src="stake.png" alt="Burger Ingredients Bottom" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '75px', marginBottom: '10px' }} />
                   <h3>Inscribe & Lock on chain BRC-20 tokens</h3>
                 </Col>
                 <Col xs={24} sm={12} md={6}>
                   <img src="clock.png" alt="Burger Ingredients Bottom" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '75px', marginBottom: '10px' }} />
                   <h3>Wait during lock-up period</h3>
                 </Col>
                 <Col xs={24} sm={12} md={6}>
                   <img src="profit.png" alt="Burger Ingredients Bottom" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '75px', marginBottom: '10px' }} />
                   <h3>Earn passive income</h3>
                 </Col>
                 <Col xs={24} sm={12} md={6}>
                   <img src="claim.png" alt="Burger Ingredients Bottom" style={{ width: 'auto', height: 'auto', maxWidth: '100%', maxHeight: '75px', marginBottom: '10px' }} />
                   <h3>Unlock & Claim BRC-20 tokens</h3>
                 </Col>
               </Row>
             </Col>
           </Row>
       </Row>
       <Row gutter={[16, 16]} justify="center" align="middle" style={{ backgroundColor: '#0d3a4d', color: '#f4f4f4', height:'auto'}}>
         <Col xs={24} sm={24} md={8} style={{  minHeight: '350px', padding: '20px', color: '#f4f4f4' }}>
         <div>
            <h2 style={{ color: '#f7931a', fontSize: '38px', lineHeight: '120%', textAlign: 'left' }}>Liquidity Locking</h2>

                <p style={{ color: '#f4f4f4', fontSize: '18px', lineHeight: '150%', textAlign: 'left' }}>Wondering what is Liquidity staking with CLTV Finance?</p>
               <ul>
                 <li><span style={{ color: '#f7931a'}}>Mechanism:</span> Liquidity Locking with CLTV Finance involves locking BRC-20 tokens using HTLC on the Bitcoin blockchain, creating scarcity and demonstrating commitment to the project</li>
                 <li><span style={{ color: '#f7931a'}}>Trust and Commitment:</span> This locking process builds trust among participants by showing a strong financial commitment, as holders renounce the ability to sell their tokens during the lock-up period.</li>
                 <li><span style={{ color: '#f7931a'}}>Benefits:</span>Participants may receive rewards, extra returns, voting rights, or token airdrops as incentives for locking their tokens.</li>
               </ul>
             </div>
         </Col>
         <Col xs={24} sm={24} md={8} style={{  minHeight: '350px', padding: '20px', color: '#f4f4f4' }}>
           <img src="yield_generation_1.png" alt="Logo" style={{ height: 'auto', maxWidth: '100%' }} />
         </Col>

       </Row>

       <Row justify="center">
         <Col flex="auto" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontSize: '1.5em', minHeight: '80vh', padding: '30px' }}>
           <h1 style={{ color: '#0d3a4d', fontSize: '2em', marginBottom: '20px' }}>Frequently Asked Questions</h1>
           <ul style={{ listStyle: 'none', padding: 0 }}>
             {faqData.map((faq, index) => (
               <li key={index} style={listItemStyle} onClick={() => handleQuestionClick(index)}>
                 <strong style={{ fontSize: '1.2em', color: '#0d3a4d' }}>{faq.question}</strong>
                 {openIndex === index && <p style={{ color: '#0d3a4d', marginTop: '10px' }}>{faq.answer}</p>}
                 <DownOutlined style={{ ...arrowIconStyle, color: '#f7931a' }} />
               </li>
             ))}
           </ul>
         </Col>
       </Row>
        <Modal
          title={`Claim ${claimTokenType ? claimTokenType.toUpperCase() : ''} Tokens`}
          visible={isClaimModalVisible}
          onCancel={handleHideClaimModal}
          footer={[
            <Button key="cancel" onClick={handleHideClaimModal}>
              Cancel
            </Button>,
            <Button key="claim" type="primary" loading={claiming} onClick={handleClaim}>
              Claim
            </Button>,
          ]}
        >
          <p>Do you want to claim your {claimTokenType ? claimTokenType.toUpperCase() : ''} tokens?</p>
        </Modal>
        <Modal 
           title={<Text style={{ color: '#0d3a4d', fontSize: '20px', fontWeight: 'bold' }}>Select UTXO</Text>}
           visible={isUTXOModalVisible} 
           onCancel={() => setIsUTXOModalVisible(false)}
           footer={[
             <Button key="back" onClick={() => setIsUTXOModalVisible(false)} style={{ borderColor: '#f7931a', color: '#f7931a' }}>
               Cancel
             </Button>,
             <Button key="submit" type="primary" onClick={handleStakeUTXO} style={{ backgroundColor: '#f7931a', borderColor: '#f7931a', color: '#ffffff' }}>
               Stake Selected
             </Button>,
           ]}
         >
           <p style={{ color: '#0d3a4d', marginBottom: '20px' }}>Select the UTXOs you wish to stake from the list below. Staking helps in earning rewards and increasing your asset value.</p>
           <List
             itemLayout="horizontal"
             dataSource={utxo.filter(item => item.satoshi >= 1000)}
             renderItem={(item) => (
               <List.Item 
                 actions={[
                   <Checkbox 
                     onChange={() => toggleUTXOSelection(item)} 
                     checked={selectedUTXO.some(ut => ut.txid === item.txid && ut.vout === item.vout)} 
                     style={{ color: '#0d3a4d' }} 
                   />
                 ]}
                 style={{ borderBottom: '1px solid #e3e3e3', padding: '10px' }}
               >
                 <List.Item.Meta 
                   title={<Text strong style={{ color: '#0d3a4d' }}>UTXO</Text>} 
                   description={
                     <div style={{ color: '#f7931a' }}>
                       <Row align="middle" justify="space-between" gutter={16}>
                         <Col span={12} style={{ textAlign: 'left' }}>
                           <Text strong style={{ fontSize: '1.2em' }}>
                             {(item.satoshi / 100000000).toFixed(8)} BTC
                           </Text>
                         </Col>
                         <Col span={4} style={{ textAlign: 'center' }}>
                           <img
                             src="/btc.png"
                             alt="BTC"
                             style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                           />
                         </Col>
                         <Col span={8} style={{ textAlign: 'right' }}>
                           <a href={`https://mempool.space/tx/${item.txid}`} target="_blank" rel="noopener noreferrer" style={{ fontSize: '1.2em', color: '#f7931a' }}>
                             View on Explorer
                           </a>
                         </Col>
                       </Row>
                     </div>
                   }
                   style={{ margin: '0' }}
                 />
               </List.Item>
             )}
             style={{ backgroundColor: '#ffffff', borderRadius: '5px', overflow: 'hidden' }}
           />
         </Modal>
        <Modal 
            title={<Text style={{ color: '#0d3a4d', fontSize: '20px', fontWeight: 'bold' }}>Stake Your Inscriptions</Text>}
            visible={isModalVisible} 
            onOk={handleStake} 
            onCancel={() => setIsModalVisible(false)}
            style={{ backgroundColor: '#f4f4f4', padding: '20px' }}
            footer={[
                <Button key="back" onClick={() => setIsModalVisible(false)} style={{ borderColor: '#f7931a', color: '#f7931a' }}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleStake} style={{ backgroundColor: '#f7931a', borderColor: '#f7931a', color: '#ffffff' }}>
                    Stake Selected
                </Button>,
            ]}
        >
            <p style={{ color: '#0d3a4d', marginBottom: '20px' }}>Select the inscriptions you wish to stake from the list below. Staking helps in earning rewards and increasing your asset value.</p>
            <List
                itemLayout="horizontal"
                dataSource={inscriptions.filter(item => item.jsonData && selectedToken && item.jsonData.tick.toLowerCase() === selectedToken)}
                renderItem={(item) => (
                    <List.Item 
                        actions={[
                            <Checkbox 
                                onChange={() => toggleInscriptionSelection(item)} 
                                checked={selectedInscriptions.some(si => si.inscriptionId === item.inscriptionId)} 
                                style={{ color: '#0d3a4d' }} 
                            />
                        ]}
                        style={{ borderBottom: '1px solid #e3e3e3', padding: '10px' }}
                    >
                        <List.Item.Meta 
                            title={<Text strong style={{ color: '#0d3a4d' }}>Inscriptions</Text>} 
                            description={
                                <div style={{ color: '#f7931a' }}>
                                    <Row align="middle" justify="space-between" gutter={16}>
                                        <Col span={12} style={{ textAlign: 'left' }}>
                                            <Text strong style={{ fontSize: '1.2em' }}>
                                                {item.jsonData.amt} {item.jsonData.tick.toUpperCase()}
                                            </Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'center' }}>
                                            <img
                                                src={`/${item.jsonData.tick.toLowerCase()}.png`}
                                                alt={item.jsonData.tick.toUpperCase()}
                                                style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            }
                            style={{ margin: '0' }}
                        />
                    </List.Item>
                )}
                style={{ backgroundColor: '#ffffff', borderRadius: '5px', overflow: 'hidden' }}
            />
        </Modal>
        <Modal 
            title={<Text style={{ color: '#0d3a4d', fontSize: '20px', fontWeight: 'bold' }}>Select Runes</Text>}
            visible={isRuneModalVisible} 
            onCancel={() => setIsRuneModalVisible(false)}
            footer={[
                <Button key="back" onClick={() => setIsRuneModalVisible(false)} style={{ borderColor: '#f7931a', color: '#f7931a' }}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={handleStakeRune} style={{ backgroundColor: '#f7931a', borderColor: '#f7931a', color: '#ffffff' }}>
                    Stake Selected
                </Button>,
            ]}
        >
            <p style={{ color: '#0d3a4d', marginBottom: '20px' }}>Select the runes you wish to stake from the list below. Staking helps in earning rewards and increasing your asset value.</p>
            <List
                itemLayout="horizontal"
                dataSource={runes} // Utilisation de l'état `runes` ici
                renderItem={(rune) => {
                    let displayAmount = parseFloat(rune.amount);
                    if (rune.runeId === "840000:3") {
                        displayAmount /= 100000;
                    } else if (rune.runeId === "840456:5478") {
                        displayAmount /= 100;
                    }
                    return (
                        <List.Item 
                            actions={[
                                <Checkbox 
                                    onChange={() => toggleRuneSelection(rune)} 
                                    checked={selectedRunes.some(sr => sr.runeId === rune.runeId)} 
                                    style={{ color: '#0d3a4d' }} 
                                />
                            ]}
                            style={{ borderBottom: '1px solid #e3e3e3', padding: '10px' }}
                        >
                            <List.Item.Meta 
                                title={<Text strong style={{ color: '#0d3a4d' }}>Runes</Text>} 
                                description={
                                    <div style={{ color: '#f7931a' }}>
                                        <Row align="middle" justify="space-between" gutter={16}>
                                            <Col span={12} style={{ textAlign: 'left' }}>
                                                <Text strong style={{ fontSize: '1.2em' }}>
                                                    {displayAmount.toFixed(0)} {rune.symbol.toUpperCase()}
                                                </Text>
                                            </Col>
                                            <Col span={12} style={{ textAlign: 'center' }}>
                                                <img
                                                    src={`/${rune.rune.slice(0, 3).toLowerCase()}.png`}
                                                    alt={rune.symbol.toUpperCase()}
                                                    style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                style={{ margin: '0' }}
                            />
                        </List.Item>
                    );
                }}
                style={{ backgroundColor: '#ffffff', borderRadius: '5px', overflow: 'hidden' }}
            />
        </Modal>

        <Modal
          title={`Unstake ${unstakeTokenType?.toUpperCase()}`}
          visible={isUnstakeModalVisible}
          onCancel={handleHideUnstakeModal}
          onOk={handleHideUnstakeModal}
        >
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <a href={`https://mempool.space/address/${stakingAddress}`} target="_blank" rel="noopener noreferrer" style={{ marginBottom: '16px' }}>
              <Text style={{ color: '#f7931a', fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline' }}>{stakingAddress ? stakingAddress : 'Your Smart Wallet'}</Text>
            </a>
            <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
              <label style={{ marginRight: '8px', fontSize: '16px', fontWeight: 'bold', width: '100px' }}>Quantity:</label>
             <input
               type="number"
               step="any"
               value={unstakeAmount || ''}
               onChange={(e) => setUnstakeAmount(parseFloat(e.target.value))}
               style={{ flex: '1', padding: '8px', borderRadius: '8px', border: '1px solid #ccc' }}
             />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
              <label style={{ marginRight: '8px', fontSize: '16px', fontWeight: 'bold', width: '100px' }}>Fees:</label>
              <InputNumber
                value={fees}
                onChange={handleFeesChange}
                min={10}
                step={17}
                style={{ flex: '1', padding: '8px', borderRadius: '8px', border: '1px solid #ccc' }}
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
            <Button onClick={handleUnstake} style={{ backgroundColor: '#0d3a4d',color :'#f7931a',  fontSize: '19px' ,fontWeight: '400',height:'100%',margin:'5%', borderRadius: '20px',padding: '15px 50px',width: 'auto' }} >Unstake</Button>
          </div>
        </Modal>

    </div>
  );
};

export default Borrow;
